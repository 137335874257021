export default {
    state: () => ({
        allPools: null,
        poolFilter: null,
        currentPool: null,
        firstToken: null,
        secondToken: null,
        transferPool: null,
        firstAmount: 0,
        secondAmount: 0,
    }),
    mutations: {
        SET_ALL_POOLS(state, value) {
            state.allPools = value
        },
        SET_POOL_FILTER(state, value) {
            state.poolFilter = value
        },
        SET_CURRENT_POOL(state, value) {
            state.currentPool = value
        },
        SET_FIRST_POOL_TOKEN(state, value) {
            state.firstToken = value
        },
        SET_SECOND_POOL_TOKEN(state, value) {
            state.secondToken = value
        },
        SET_TRANSFER_CURRENT_POOL(state, value) {
            state.transferPool = value
        },
        SET_FIRST_AMOUNT(state, value) {
            state.firstAmount = value
        },
        SET_SECOND_AMOUNT(state, value) {
            state.secondAmount = value
        },
    },
    actions: {
        SAVE_ALL_POOLS({commit}, item) {
            commit('SET_ALL_POOLS', item)
        },
        SAVE_POOL_FILTER({commit}, item) {
            commit('SET_POOL_FILTER', item)
        },
        SAVE_CURRENT_POOL({commit}, item) {
            commit('SET_CURRENT_POOL', item)
        },
        SAVE_FIRST_POOL_TOKEN({commit}, item) {
            commit('SET_FIRST_POOL_TOKEN', item)
        },
        SAVE_SECOND_POOL_TOKEN({commit}, item) {
            commit('SET_SECOND_POOL_TOKEN', item)
        },
        SAVE_TRANSFER_CURRENT_POOL({commit}, item) {
            commit('SET_TRANSFER_CURRENT_POOL', item)
        },
        SAVE_FIRST_AMOUNT({commit}, item) {
            commit('SET_FIRST_AMOUNT', item)
        },
        SAVE_SECOND_AMOUNT({commit}, item) {
            commit('SET_SECOND_AMOUNT', item)
        },
    },
    getters: {
        GET_ALL_POOLS: state => {
            return state.allPools;
        },
        GET_POOL_FILTER: state => {
            return state.poolFilter
        },
        GET_CURRENT_POOL: state => {
            return state.currentPool
        },
        GET_FIRST_POOL_TOKEN: state => {
            return state.firstToken
        },
        GET_SECOND_POOL_TOKEN: state => {
            return state.secondToken
        },
        GET_TRANSFER_CURRENT_POOL: state => {
            return state.transferPool
        },
        GET_FIRST_AMOUNT: state => {
            return state.firstAmount
        },
        GET_SECOND_AMOUNT: state => {
            return state.secondAmount
        },
    }
}
