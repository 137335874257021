<template>
    <svg :class="customClass" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M18.9993 6.63959C19.6651 5.91074 20.3237 5.19062 20.9822 4.47001C20.988 4.47096 20.9937 4.47247 21 4.47341C20.7605 7.0423 20.521 9.61174 20.2804 12.1947C17.5213 9.61558 14.7709 7.04425 12.0127 4.46611C10.5943 6.01648 9.17392 7.56929 7.7473 9.1289C7.77128 9.15507 7.79964 9.19099 7.83276 9.22206C8.259 9.61948 8.68665 10.0154 9.11295 10.4129C9.8368 11.0894 10.5597 11.7668 11.2836 12.4436C12.0175 13.1298 12.7523 13.8154 13.4864 14.5015C13.5655 14.5759 13.6414 14.6534 13.7192 14.7291C13.7566 14.7655 13.7562 14.7907 13.7163 14.833C13.5209 15.0383 13.3303 15.2483 13.1383 15.4576C12.6709 15.9675 12.2038 16.4779 11.7372 16.989C11.7117 17.0166 11.6905 17.0487 11.6638 17.083C11.6575 17.0797 11.6445 17.0753 11.6354 17.0671C11.0522 16.5227 10.469 15.9777 9.8867 15.4327C9.44945 15.0237 9.01359 14.6127 8.57578 14.2042C7.6753 13.3631 6.77384 12.5232 5.8738 11.6822C5.14657 11.0034 4.42033 10.3236 3.69409 9.64325C3.58991 9.5457 3.48911 9.44429 3.38687 9.34485C3.36096 9.31962 3.34944 9.29773 3.38156 9.26427C3.54095 9.09688 3.6984 8.92749 3.85487 8.7572C4.18129 8.4015 4.50672 8.04535 4.83265 7.68918C5.29682 7.18156 5.76004 6.67306 6.22421 6.1655C6.5338 5.82726 6.84489 5.48999 7.15448 5.15182C7.6484 4.61173 8.14186 4.07065 8.63582 3.53056C8.99005 3.14334 9.34527 2.7566 9.6995 2.36937C10.3375 1.67158 10.9744 0.973304 11.6119 0.275524C11.6825 0.19836 11.753 0.12121 11.8226 0.043086C11.8735 -0.0132089 11.8802 -0.013689 11.9368 0.0392032C12.5206 0.583163 13.1043 1.12712 13.6875 1.67158C14.5261 2.45479 15.3641 3.23894 16.2022 4.02259C16.7441 4.52871 17.2871 5.03437 17.8281 5.54093C18.2048 5.89375 18.5802 6.24896 18.956 6.60267C18.9715 6.61726 18.9888 6.6304 18.9993 6.63959Z" fill="#FFFDFD"/>
        <path d="M3 19.5357C3.24098 16.9498 3.48001 14.379 3.71954 11.8052C3.7258 11.809 3.73732 11.8139 3.74641 11.8222C4.09394 12.142 4.44195 12.4607 4.78755 12.7825C5.3425 13.2988 5.89544 13.8166 6.44888 14.3338C7.02299 14.8704 7.59614 15.4081 8.17024 15.9449C8.93537 16.6596 9.70148 17.3735 10.4657 18.0891C10.9543 18.5467 11.44 19.0067 11.9273 19.4658C11.9416 19.4794 11.9527 19.4987 11.9691 19.5085C11.9801 19.5148 12.0051 19.5153 12.0118 19.5075C12.1001 19.409 12.1841 19.3061 12.2734 19.2086C12.775 18.6588 13.2771 18.1096 13.7797 17.5607C14.1991 17.1021 14.6201 16.645 15.0397 16.1869C15.4285 15.7628 15.8163 15.3383 16.2047 14.9137C16.2671 14.8457 16.2642 14.8452 16.1952 14.7817C15.8821 14.4924 15.5706 14.2018 15.259 13.9111C14.5141 13.2158 13.7691 12.5204 13.025 11.8246C12.2177 11.0695 11.4103 10.3145 10.6034 9.55896C10.4834 9.44635 10.3649 9.33181 10.2391 9.21198C10.888 8.50206 11.5361 7.79308 12.1899 7.07788C14.9534 9.6613 17.724 12.252 20.498 14.8452C17.7028 17.9009 14.9183 20.9449 12.1235 24C9.7557 21.7868 7.38303 19.5697 5.00404 17.3463C4.33878 18.0732 3.67152 18.8015 3 19.5357Z" fill="#D6BDAF"/>
    </svg>
</template>

<script>
export default {
    name: "SwapLogo",
    props: {
        customClass: {
            type: String,
            default() {
                return ''
            }
        },
    },
    data() {
        return {}
    }
}
</script>

<style scoped>

</style>
