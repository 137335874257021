<template>
    <header class="header">
        <div class="header__logo-group">
            <div class="header__logo-wrapper">
                <SwapLogo class="coffee-logo"/>
            </div>
            <SwapLogoText class="coffee-text"/>
        </div>
        <label for="" class="header__label">
            <SearchIcon :custom-class="'header__search-icon'" />
            <input type="text" class="header__input" :placeholder="'Search by pools, wallet, address, or domain'">
            <button class="header__btn">/</button>
        </label>
        <div class="header__group">
<!--            <div class="header__token"></div>-->
            <button
                v-show="GET_DEX_WALLET !== null"
                class="header__dex-wallet"
                @click="showWalletSettingsPopup = true"
            >
                <img :src="GET_DEX_WALLET?.imgUrl" alt="wallet logo" class="header__dex-image" />
                <p class="header__dex-address">
                    {{ getTrimAddress }}
                </p>
                <ArrowIcon custom-class="arrow-icon"/>
            </button>
            <button v-show="GET_DEX_WALLET === null" class="header__dex-button" @click="showTonconnect">
                <WalletIcon />
                {{ $t('dexButton.connectWallet') }}
            </button>
            <button class="header__burger">
                <BurgerIcon />
            </button>
        </div>
    </header>
    <WalletSettingsPopup
        v-if="showWalletSettingsPopup"
        :wallet-image="tonConnectUi?.walletInfo?.imageUrl"
        @close-settings="showWalletSettingsPopup = false"
        @logout="disconnectWallet"
    />
</template>

<script>
import SearchIcon from "@/assets/earn/all-pools/SearchIcon.vue";
import {mapActions, mapGetters} from "vuex";
import computedMixins from "@/mixins/computedMixins.js";
import methodsMixins from "@/mixins/methodsMixins.js";
import WalletSettingsPopup from "@/components/WalletSettingsPopup.vue";
import ArrowIcon from "@/assets/earn/sidebar/ArrowIcon.vue";
import WalletIcon from "@/assets/earn/swap-interface/WalletIcon.vue";
import SwapLogo from "@/assets/earn/sidebar/SwapLogo.vue";
import SwapLogoText from "@/assets/earn/sidebar/SwapLogoText.vue";
import BurgerIcon from "@/assets/earn/all-pools/BurgerIcon.vue";

export default {
    name: "EarnHeader",
    components: {BurgerIcon, SwapLogoText, SwapLogo, WalletIcon, ArrowIcon, WalletSettingsPopup, SearchIcon},
    mixins: [computedMixins, methodsMixins],
    props: {
        tonConnectUi: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            showWalletSettingsPopup: false,
        }
    },
    computed: {
        ...mapGetters([
            'GET_DEX_WALLET',
        ])
    },
    methods: {
        ...mapActions([
           'SAVE_USER_SETTINGS',
        ]),
        async disconnectWallet() {
            try {
                await this.tonConnectUi.disconnect();
                this.showWalletSettingsPopup = false;
                this.SAVE_USER_SETTINGS(null);
            } catch (err) {
                console.error(err);
            }
        },
        async showTonconnect() {
            try {
                await this.tonConnectUi.openModal();
                console.log('modal is open');
            } catch (err) {
                console.error(err);
            }
        },
    }
}
</script>

<style scoped>
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding-bottom: 20px;
    max-width: 1100px;
    width: 1100px;
    margin: 0 auto;
    border-bottom: 1px solid var(--iface-white6);
}

.header__logo-group {
    display: none;
}

.header__label {
    display: flex;
    align-items: center;
    min-width: 321px;
    gap: 10px;
    height: 40px;
    background: var(--iface-white6);
    border-radius: 12px;
    padding: 0 8px;
}

.header__search-icon {
    min-width: 20px;
}

.header__group {
    display: flex;
    align-items: center;
    gap: 12px;
}

.header__input {
    width: 100%;
    background: transparent;
    border: none;
    outline: none;
    font-size: 13px;
    opacity: 1;
    text-overflow: ellipsis;
}

.header__input::placeholder {
    color: #fff;
    opacity: 0.4;
}

.header__btn {
    min-width: 24px;
    width: 24px;
    height: 24px;
    padding: 4px 6px;
    background: var(--iface-white6);
    border-radius: 8px;
    border: none;
    font-size: 12px;
    line-height: 16px;
}

.header__dex-wallet {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 12px;
    border: none;
    outline: none;
    background: var(--iface-white6);
    gap: 8px;
    height: 40px;
    cursor: pointer;
}

.header__dex-wallet:hover .header__dex-address {
    opacity: 1;
}

.theme-light .header__dex-wallet {
    background: transparent;
    border: 1px solid var(--iface-white10);
}

.header__dex-image {
    border-radius: 100px;
    width: 24px;
    height: 24px;
}

.header__dex-address {
    transition: 0.2s;
    max-width: 85px;
    white-space: nowrap;
    font-size: 14px;
    line-height: 16px;
    opacity: 0.8;
}

.header__dex-button {
    display: flex;
    align-items: center;
    gap: 8px;
    transition: 0.2s;
    min-width: 138px;
    padding: 0 10px;
    height: 40px;
    border-radius: 12px;
    background: var(--iface-white6);
    border: none;
    outline: none;
    font-size: 13px;
    line-height: normal;
    white-space: nowrap;
    color: #fff;
}

.header__dex-button:hover {
    background: var(--iface-white10);
}

.arrow-icon {
    min-width: 20px;
    height: 20px;
    transform: rotate(90deg);
}

.header__burger {
    display: none;
}

@media screen and (max-width: 1420px) {
  .header {
    max-width: 100%;
    width: 100%;
  }
}


@media screen and (max-width: 1220px) {
    .header {
        position: fixed;
        top: 0;
        z-index: 900;
        width: 100%;
        max-width: 100%;
        background: var(--earn-bg);
        padding: 16px;
        border: none;
        border-radius: 0 0 16px 16px;
        margin-bottom: 6px;
    }

    .header__label {
        max-width: 100%;
        min-width: auto;
        width: 100%;
    }

    .header__logo-group {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-right: 16px;
    }

    .header__logo-wrapper {
        width: 40px;
        height: 40px;
        padding: 5px;
        border-radius: 15px;
        background: #37262C;
    }

    .coffee-logo {
        width: 30px;
        height: 30px;
    }

    .coffee-text {
        width: 151px;
        height: 27.5px;
    }

    .header__burger {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 40px;
        width: 40px;
        height: 40px;
        border: none;
        border-radius: 12px;
        outline: none;
        background: var(--iface-white6);
    }

    .header__btn {
        display: none;
    }
}

@media screen and (max-width: 576px) {
    .header__logo-group {
        margin-right: 0;
    }

    .coffee-text {
        display: none;
    }
}
</style>
