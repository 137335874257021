<template>
    <svg :class="customClass" xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
        <g opacity="0.8">
            <path d="M8.60208 5.72708L2.97708 11.3521C2.92482 11.4043 2.88337 11.4664 2.85508 11.5347C2.8268 11.603 2.81224 11.6761 2.81224 11.7501C2.81224 11.824 2.8268 11.8971 2.85508 11.9654C2.88337 12.0337 2.92482 12.0958 2.97708 12.148C3.02935 12.2003 3.09139 12.2417 3.15967 12.27C3.22796 12.2983 3.30114 12.3129 3.37505 12.3129C3.44896 12.3129 3.52215 12.2983 3.59043 12.27C3.65872 12.2417 3.72076 12.2003 3.77302 12.148L9.00005 6.92029L14.2271 12.148C14.3326 12.2536 14.4758 12.3129 14.6251 12.3129C14.7743 12.3129 14.9175 12.2536 15.023 12.148C15.1286 12.0425 15.1879 11.8993 15.1879 11.7501C15.1879 11.6008 15.1286 11.4576 15.023 11.3521L9.39802 5.72708C9.34578 5.67479 9.28374 5.6333 9.21546 5.60499C9.14717 5.57668 9.07397 5.56211 9.00005 5.56211C8.92613 5.56211 8.85294 5.57668 8.78465 5.60499C8.71636 5.6333 8.65433 5.67479 8.60208 5.72708Z" fill="white"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: "ArrowIcon",
    props: {
        customClass: {
            type: String,
            default() {
                return ''
            }
        }
    },
    data() {
        return {}
    }
}
</script>

<style scoped>

</style>
