import { createRouter, createWebHistory } from 'vue-router';
import AppWrapper from '@/views/AppWrapper.vue';

const routes = [
	{
		path: '',
		name: 'Main',
		redirect: {name: "Dex"},
	},
	{
		path: '/',
		name: 'AppWrapper',
		component: AppWrapper,
		children: [
            {
                path: '',
                name: 'DexContainer',
	            component: () => import("@/views/dex/DexContainer.vue"),
                children: [
                    {
                        path: 'dex',
                        name: 'Dex',
                        component: () => import("@/views/dex/DexPageTest.vue"),
                    },
                    // {
                    //     path: 'dex',
                    //     name: 'Dex',
                    //     component: () => import("@/views/dex/DexPage.vue"),
                    // },
                    {
                        path: 'limit',
                        name: 'Limit',
                        component: () => import("@/views/limit/LimitPage.vue"),
                    },
                ]
            },
			{
				path: 'referral',
				name: 'Referral',
				component: () => import("@/views/referral/ReferralPage.vue")
			},
			{
				path: 'contests',
				name: 'Contests',
				component: () => import("@/views/contests/TradingContests.vue")
			},
			{
				path: 'contests/:id',
				name: 'ContestPage',
				component: () => import("@/views/contests/ContestPage.vue"),
			},
			{
				path: 'cashback',
				name: 'Cashback',
				component: () => import("@/views/cashback/CashbackPage.vue"),
			},
			{
				path: 'cashback/:id',
				name: 'CashbackItemPage',
				component: () => import("@/views/cashback/CashbackItemPage.vue"),
				props: true,
			},
			{
				path: 'claim',
				name: 'Claim',
				component: () => import("@/views/claim/ClaimCenter.vue")
			},
            {
                path: 'earn/',
                name: 'EarnContainer',
                component: () => import("@/views/earn/EarnContainer.vue"),
                children: [
                    {
                        path: '',
                        name: 'AllPools',
                        component: () => import("@/views/earn/AllPools.vue"),
                    },
                    {
                        path: 'pool/:id',
                        name: 'PoolPage',
                        component: () => import("@/views/earn/PoolPage.vue")
                    },
                    {
                        path: 'deposit',
                        name: 'DepositLiquidity',
                        component: () => import("@/views/earn/DepositLiquidity.vue"),
                    },
                    {
                        path: 'create-pool',
                        name: 'CreatePool',
                        component: () => import("@/views/earn/CreatePool.vue"),
                    },
                    {
                        path: 'transfer',
                        name: 'TransferLiquidity',
                        component: () => import("@/views/earn/TransferLiquidity.vue"),
                    },
                ]
            },
			{
				path: 'stake/:name?',
				name: 'Stake',
				component: () => import("@/views/stake/StakePage.vue"),
				props: true,
				beforeEnter: (to, from, next) => {
					if (!to.params.name) {
						next({ name: 'StakeCatalogue' });
					} else {
						next();
					}
				}
			},
			{
				path: 'stakes',
				name: 'StakeCatalogue',
				component: () => import("@/views/stake/StakeCataloguePage.vue")
			}
		]
	},
	// {
	// 	path: '/random-presale',
	// 	name: 'Presale',
	// 	component: PresalePage,
	// },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  // scrollBehavior(to, from, savedPosition) {
  // 	if (to.hash) {
  // 		return {
  // 			el: to.hash,
  // 		}
  // 	} else {
  // 		return {
  // 			left: 0,
  // 			top: 0
  // 		}
  // 	}
  // }
});

export default router;
