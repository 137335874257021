export default {
	state: () => ({
		expertMode: false,
		maxSuborders: 1,
		maxInvocations: 1,
	}),
	mutations: {
		SET_LIMIT_EXPERT_MODE(state, value) {
			state.expertMode = value;
		},
		SET_MAX_SUBORDERS(state, value) {
			state.maxSuborders = value;
		},
		SET_MAX_INVOCATION(state, value) {
			state.maxInvocations = value;
		},
		CLEAR_EXPERTS_SETTINGS(state) {
			state.maxInvocations = 1
		},
	},
	actions: {
		LIMIT_EXPERT_MODE({ commit }, item) {
			commit('SET_LIMIT_EXPERT_MODE', item);
		},
		LIMIT_MAX_SUBORDERS({ commit }, item) {
			commit('SET_MAX_SUBORDERS', item);
		},
		LIMIT_MAX_INVOCATIONS({ commit }, item) {
			commit('SET_MAX_INVOCATION', item);
		},
		CLEAR_LIMIT_EXPERT_SETTINGS({ commit }, item) {
			commit('CLEAR_EXPERTS_SETTINGS', item);
		},
	},
	getters: {
		GET_LIMIT_EXPERT_MODE: (state) => {
			return state.expertMode;
		},
		GET_LIMIT_SUBORDERS: (state) => {
			return state.maxSuborders;
		},
		GET_LIMIT_INVOCATIONS: (state) => {
			return state.maxInvocations;
		},
	},
};
