export default {
	state: () => ({
		trInfo: null,
		status: null,
	}),
	mutations: {
		SET_TRANSACTION_INFO(state, value) {
			state.trInfo = value;
		},
		SET_TRANSACTION_STATUS(state, value) {
			state.status = value;
		},
	},
	actions: {
		SAVE_TRANSACTION_INFO({ commit }, item) {
			commit('SET_TRANSACTION_INFO', item);
		},
		SAVE_TRANSACTION_STATUS({ commit }, item) {
			commit('SET_TRANSACTION_STATUS', item);
		},
	},
	getters: {
		GET_TRANSACTION_INFO: (state) => {
			return state.trInfo;
		},
		GET_TRANSACTION_STATUS: (state) => {
			return state.status;
		},
	},
};
