export default {
	state: () => ({
        strategiesEligible: false,
        strategiesWallet: null,
		supportedSend: [],
		supportedReceive: [],
		limitFirst: null,
		limitSecond: null,
		firstAmount: 0,
		secondAmount: 0,
		tokenRate: 0,
	}),
	mutations: {
        SET_STRATEGIES_ELIGIBLE(state, value) {
            state.strategiesEligible = value;
        },
        SET_STRATEGIES_WALLET(state, value) {
            state.strategiesWallet = value;
        },
		SET_LIMIT_SEND_SUPPORTED_TOKENS(state, value) {
			state.supportedSend = value;
		},
		SET_LIMIT_RECEIVE_SUPPORTED_TOKENS(state, value) {
			state.supportedReceive = value;
		},
		SET_LIMIT_FIRST_TOKEN(state, value) {
			state.limitFirst = value;
		},
		SET_LIMIT_SECOND_TOKEN(state, value) {
			state.limitSecond = value;
		},
		SET_LIMIT_FIRST_AMOUNT(state, value) {
			state.firstAmount = value;
		},
		SET_LIMIT_SECOND_AMOUNT(state, value) {
			state.secondAmount = value;
		},
		SET_LIMIT_TOKEN_RATE(state, value) {
			state.tokenRate = value;
		},
	},
	actions: {
        STRATEGIES_ELIGIBLE({ commit }, item) {
            commit('SET_STRATEGIES_ELIGIBLE', item);
        },
        STRATEGIES_WALLET({commit}, item) {
            commit('SET_STRATEGIES_WALLET', item);
        },
        LIMIT_SEND_SUPPORTED_TOKENS({commit}, item) {
	        commit('SET_LIMIT_SEND_SUPPORTED_TOKENS', item);
        },
		LIMIT_RECEIVE_SUPPORTED_TOKENS({commit}, item) {
			commit('SET_LIMIT_RECEIVE_SUPPORTED_TOKENS', item);
		},
		LIMIT_FIRST_TOKEN({ commit }, item) {
			commit('SET_LIMIT_FIRST_TOKEN', item);
		},
		LIMIT_SECOND_TOKEN({ commit }, item) {
			commit('SET_LIMIT_SECOND_TOKEN', item);
		},
		LIMIT_FIRST_AMOUNT({ commit }, item) {
			commit('SET_LIMIT_FIRST_AMOUNT', item);
		},
		LIMIT_SECOND_AMOUNT({ commit }, item) {
			commit('SET_LIMIT_SECOND_AMOUNT', item);
		},
		LIMIT_TOKEN_RATE({ commit }, item) {
			commit('SET_LIMIT_TOKEN_RATE', item);
		},
	},
	getters: {
        GET_STRATEGIES_ELIGIBLE: (state) => {
            return state.strategiesEligible;
        },
		GET_STRATEGIES_WALLET: (state) => {
			return state.strategiesWallet;
		},
		GET_LIMIT_SEND_LIST: (state) => {
			return state.supportedSend;
		},
		GET_LIMIT_RECEIVE_LIST: (state) => {
			return state.supportedReceive
		},
		GET_LIMIT_FIRST_TOKEN: (state) => {
			return state.limitFirst;
		},
		GET_LIMIT_SECOND_TOKEN: (state) => {
			return state.limitSecond;
		},
		GET_LIMIT_FIRST_AMOUNT: (state) => {
			return state.firstAmount;
		},
		GET_LIMIT_SECOND_AMOUNT: (state) => {
			return state.secondAmount;
		},
		GET_LIMIT_TOKEN_RATE: (state) => {
			return state.tokenRate;
		},
	},
};
