<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5411 8.503C17.1504 8.50946 17.9969 8.51223 18.7149 8.50946C19.0824 8.50854 19.269 8.05602 19.0145 7.78267C18.5537 7.28951 17.9118 6.6015 17.2672 5.91072C16.6199 5.21717 15.9698 4.52085 15.4972 4.01477C15.2356 3.73495 14.7793 3.92704 14.7793 4.31491V6.68739C14.7793 7.68293 15.5751 8.503 16.5411 8.503Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9017 15.6385L13.3028 17.2777C13.1733 17.4116 12.9977 17.4828 12.8212 17.4828C12.7333 17.4828 12.6464 17.4652 12.5622 17.4301C12.3087 17.323 12.1421 17.07 12.1421 16.7901V11.3433C12.1421 10.961 12.4463 10.6507 12.8212 10.6507C13.196 10.6507 13.5002 10.961 13.5002 11.3433V15.1112L13.9384 14.6615C14.2036 14.3899 14.6328 14.389 14.899 14.6587C15.1642 14.9283 15.166 15.3679 14.9017 15.6385ZM10.1838 17.4855C9.80903 17.4855 9.50483 17.1752 9.50483 16.7929L9.50392 13.0241L9.06573 13.4738C8.80136 13.7453 8.37132 13.7463 8.10514 13.4766C7.83987 13.2069 7.83806 12.7683 8.10333 12.4977L9.7022 10.8585C9.89685 10.6581 10.1884 10.5999 10.4419 10.7061C10.6954 10.8132 10.862 11.0663 10.862 11.347L10.8629 16.7929C10.8629 17.1752 10.5587 17.4855 10.1838 17.4855ZM19.1071 9.81769H16.4245C14.8265 9.80845 13.5065 8.45829 13.5065 6.81168V3.91373C13.5065 3.68839 13.3327 3.5 13.1054 3.5H8.44013C6.26455 3.5 4.5 5.32669 4.5 7.5588V17.2611C4.5 19.6013 6.35237 21.5 8.62391 21.5H15.5689C17.7436 21.5 19.5 19.6909 19.5 17.4587V10.2231C19.5 9.99777 19.3253 9.81769 19.1071 9.81769Z" fill="white"/>
    </svg>
</template>

<script>
export default {
    name: "DocumentationIcon",
    data() {
        return {}
    }
}
</script>

<style scoped>

</style>
