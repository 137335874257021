import MainColor from "@/components/ui/MainColor.vue";
import SwitchToggle from "@/components/ui/SwitchToggle.vue";
import TooltipWrapper from "@/components/ui/TooltipWrapper.vue";
import InterfaceTag from "@/components/ui/InterfaceTag.vue";
import ModalWrapper from "@/components/ui/ModalWrapper.vue"
import TabItem from "@/components/ui/TabItem.vue";
export default [
    MainColor,
    SwitchToggle,
    TooltipWrapper,
    InterfaceTag,
    ModalWrapper,
    TabItem
]
