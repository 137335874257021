<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.253 7.19737C19.0869 7.61175 19.9421 9.34207 19.9972 11.1445C20.0003 11.2477 19.9192 11.3315 19.8194 11.3315H12.0089C12.0048 11.3314 12.0007 11.3314 11.9966 11.3314C11.9924 11.3314 11.9883 11.3314 11.9842 11.3315H4.18061C4.08087 11.3315 3.9997 11.2477 4.00285 11.1445C4.05788 9.34293 4.91221 7.61354 6.74477 7.19802C6.43963 6.76008 6.25859 6.21965 6.25859 5.63687C6.25859 4.18753 7.37835 3 8.74194 3C10.4216 3 11.4239 4.27161 12.0019 5.4591C12.5798 4.27161 13.5822 3 15.2527 3C16.6254 3 17.7361 4.18753 17.7361 5.63687C17.7361 6.21936 17.5567 6.75955 17.253 7.19737ZM7.61314 5.63687C7.61314 6.31946 8.11884 6.87115 8.74194 6.87115H11.0989C10.7738 5.88934 10.0513 4.40259 8.74194 4.40259C8.11884 4.40259 7.61314 4.95428 7.61314 5.63687ZM12.8958 6.87115H15.2527C15.8758 6.87115 16.3815 6.31946 16.3815 5.63687C16.3815 4.95428 15.8758 4.40259 15.2527 4.40259C13.9614 4.40259 13.2299 5.88934 12.8958 6.87115Z" fill="white"/>
        <path d="M4.18061 12.7341C4.08087 12.7341 4 12.8178 4 12.9211V16.9138C4 19.358 5.44667 21 7.59951 21H11.1387C11.2385 21 11.3193 20.9162 11.3193 20.813V12.9211C11.3193 12.8178 11.2385 12.7341 11.1387 12.7341H4.18061Z" fill="white"/>
        <path d="M12.8544 12.7341C12.7548 12.7341 12.6738 12.8178 12.6738 12.9211V20.813C12.6738 20.9162 12.7548 21 12.8544 21H16.4005C18.5533 21 20 19.358 20 16.9138V12.9211C20 12.8178 19.9192 12.7341 19.8194 12.7341H12.8544Z" fill="white"/>
    </svg>
</template>

<script>
export default {
    name: "BenefitsIcon",
    data() {
        return {}
    }
}
</script>

<style scoped>

</style>
