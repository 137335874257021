<template>
    <router-view :ton-connect-ui="tonConnectUi"/>
</template>

<script>
import {TonConnectUI, toUserFriendlyAddress} from '@tonconnect/ui';
import tonConnectMixin from '@/mixins/tonConnectMixin';
import {mapActions, mapGetters} from 'vuex';
import methodsMixins from '@/mixins/methodsMixins';
import {contestService, geoService, referralService, strategiesService, tonApiService} from '@/api/coffeeApi/services';
import {isInsideWalletBrowser} from '@/helpers/dex/embedded-wallets.js';

export default {
    mixins: [tonConnectMixin, methodsMixins],
    data() {
        return {
            checkInterval: null,
        }
    },
    computed: {
        tonConnectUi() {
            return new TonConnectUI(this.tonConnectSettings);
        },
        ...mapGetters([
            'GET_TON_TOKENS',
            'GET_DEX_WALLET',
            'GET_PAYLOAD_ID',
            'GET_DEX_WALLET',
            'GET_PROOF_VERIFICATION',
            'GET_REFERRAL_INFO',
            'GET_CLAIM_HISTORY',
            'GET_THEME',
            'GET_CHART_VISIBLE_SETTING',
            'GET_SEND_TOKEN'
        ]),
    },
    methods: {
        ...mapActions([
            'TECHNICAL_WORKS',
            'DEX_PAYLOAD_ID',
            'DEX_PROOF_VERIFICATION',
            'DEX_WALLET',
            'DEX_WALLET_VERSION',
            'REFERRAL_INFO',
            'REFERRAL_LIST',
            'CLAIM_HISTORY',
            'DEX_USER_TOKENS',
            'DEX_TON_TOKENS',
            'DEX_SEND_TOKEN',
            'SAVE_CONTESTS',
            'STAKE_CLEAR_CATALOGUE_WALLET_DATA',
            'STRATEGIES_ELIGIBLE',
            'STRATEGIES_WALLET'
        ]),
        clearStore() {
            const clearTokens = this.GET_TON_TOKENS.map(t => {
                return {
                    ...t,
                    balance: 0
                }
            });

            this.DEX_SEND_TOKEN({...this.GET_SEND_TOKEN, balance: 0})
            this.DEX_USER_TOKENS([]);
            this.DEX_TON_TOKENS(clearTokens);
            this.STAKE_CLEAR_CATALOGUE_WALLET_DATA();
        },
        setTechnicalWorks() {
            this.TECHNICAL_WORKS(true);
        },
        subscribeConnect() {
            this.unsubscribeConnect = this.tonConnectUi.onStatusChange((wallet) => {
                if (wallet === null) {
                    this.DEX_WALLET(null);
                    this.DEX_PROOF_VERIFICATION(null);
                    localStorage.removeItem('tonProof_ver');
                    this.tonproofSetConnect();
                    this.clearStore();
                    return;
                }
                if (wallet && wallet.connectItems?.tonProof && 'proof' in wallet.connectItems.tonProof) {
                    wallet.account.userFriendlyAddress = toUserFriendlyAddress(wallet?.account.address);
                    wallet.account.imgUrl = wallet?.imageUrl;
                    this.DEX_WALLET(wallet?.account);
                    this.getContractVersion(wallet.account.userFriendlyAddress);
                    console.log('wallet wallet')
                    if (isInsideWalletBrowser("tonkeeper")) {
                        const savedProof = localStorage.getItem(`tonProof_ver_${wallet.account.userFriendlyAddress}`);
                        if (savedProof) {
                            this.DEX_PROOF_VERIFICATION(JSON.parse(savedProof));
                        } else {
                            this.checkProof(wallet);
                        }
                    } else {
                        this.checkProof(wallet);
                    }

                    this.bindReferralLink();
                    this.checkStrategiesEligible()
                }
            });
        },
        checkProof(wallet) {
            const tonProof = wallet.connectItems.tonProof.proof;

            const proof = {
                timestamp: tonProof.timestamp,
                domain_len: tonProof.domain.lengthBytes,
                domain_val: tonProof.domain.value,
                payload: tonProof.payload,
                signature: tonProof.signature,
            };

            const verification = {
                // address: wallet.account.address,
                public_key: wallet.account.publicKey,
                wallet_state_init: wallet.account.walletStateInit,
                proof: proof,
            };

            if (isInsideWalletBrowser("tonkeeper")) {
                const friendlyAddress = toUserFriendlyAddress(wallet.account.address);
                localStorage.setItem(`tonProof_ver_${friendlyAddress}`, JSON.stringify(verification));
            } else {
                localStorage.setItem('tonProof_ver', JSON.stringify(verification));
            }

            this.DEX_PROOF_VERIFICATION(verification);

            this.getUserSettings();
        },
        async getContractVersion(address) {
            try {
                let wallet = this.GET_DEX_WALLET;
                if (!wallet.version) {
                    let res = await tonApiService.getWalletVersion(address);
                    if (res?.data?.version > 0) {
                        wallet.version = res?.data?.version;
                        this.DEX_WALLET(wallet);
                        this.DEX_WALLET_VERSION(res?.data?.version);
                    }
                }
            } catch (err) {
                console.error(err);
            }
        },
        async bindReferralLink() {
            try {
                let refAddress = JSON.parse(sessionStorage.getItem('user_referral'));
                if (refAddress) {
                    let res = await referralService.bindReferralLink(
                        this.GET_DEX_WALLET?.address,
                        refAddress,
                        this.GET_PROOF_VERIFICATION,
                    );
                }
            } catch (err) {
                if (err.response.status === 403) {
                    await this.disconnectWallet();
                }
                console.error(err);
            }
        },
        async setUserLanguage() {
            const settings = localStorage.getItem('globalSettings');
            if (!settings) {
                try {
                    const userCountryCode = await geoService.getUserCountryCode();
                    if (userCountryCode) {
                        this.$i18n.locale = this.getLocaleForCountry(userCountryCode);
                        const newSettings = {
                            theme: this.GET_THEME || 'dark',
                            lang: userCountryCode.toLowerCase(),
                            chartVisible: this.GET_CHART_VISIBLE_SETTING || true
                        };
                        localStorage.setItem('globalSettings', JSON.stringify(newSettings));
                    }
                } catch (err) {
                    console.error(err);
                }
            }
        },
        async getReferralInfo() {
            if (this.GET_DEX_WALLET?.address && this.GET_PROOF_VERIFICATION) {
                if (!this.GET_REFERRAL_INFO) {
                    try {
                        let res = await referralService.getReferralInfo(
                            this.GET_DEX_WALLET.address,
                            this.GET_PROOF_VERIFICATION,
                        );
                        this.REFERRAL_INFO(res?.data);
                    } catch (err) {
                        if (err?.response?.status === 403) {
                            await this.disconnectWallet();
                        }
                        console.error(err);
                    }
                }
            }
        },
        async getContests() {
            try {
                let {data} = await contestService.getContests();
                await this.SAVE_CONTESTS(data);
            } catch (err) {
                console.error(err);
            }
        },
        async checkStrategiesEligible() {
            try {
                let res = await strategiesService.checkUserIsEligible(this.GET_DEX_WALLET?.address, this.GET_PROOF_VERIFICATION)
                this.STRATEGIES_ELIGIBLE(res)
                await this.checkStrategiesWallet()
            } catch(err) {
                console.error(err)
            }
        },
        async checkStrategiesWallet(without_create = false) {
            try {
                let res = await strategiesService.checkWalletAddress(this.GET_DEX_WALLET?.address, this.GET_PROOF_VERIFICATION)
                this.STRATEGIES_WALLET(res?.data)
                if (this.checkInterval) clearInterval(this.checkInterval)
            } catch(err) {
                if (err?.response?.status === 404 && !without_create) {
                    await this.createStrategiesWallet()
                }
            }
        },
        async createStrategiesWallet() {
            try {
                let res = await strategiesService.createStrategiesWallet(this.GET_DEX_WALLET?.address, this.GET_PROOF_VERIFICATION)
                await this.tonConnectUi.sendTransaction({
                    validUntil: Math.floor(Date.now() / 1000) + 300,
                    messages: [
                        {
                            address: res?.data.address,
                            amount: res?.data.value,
                            payload: res?.data.payload_cell,
                            stateInit: res?.data.state_init
                        }
                    ]
                })
                this.setCheckerInterval();
            } catch(err) {
                console.error(err)
            }
        },
        setCheckerInterval() {
            this.checkInterval = setInterval(() => {
                this.checkStrategiesWallet(true);
            }, 5000)
        }
    },
    mounted() {
        this.subscribeConnect();
        this.restoreUiConnection();
        this.tonproofSetConnect();
        this.setUserLanguage();
        setTimeout(() => {
            this.getContests()
        }, 500)
    },
};
</script>

<style>
@import '@/components/ui/variables.css';

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    overflow-y: scroll;
}

body {
    overflow-x: hidden;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    background: var(--main-bg-color);
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
    color: #fff;
    font-family: Roboto, sans-serif;
    font-weight: 500;
}

h1,
h2,
h3,
h4 {
    margin: 0;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    color: var(--main-text-color);
}

p {
    margin: 0;
    font-size: 16px;
    line-height: 19px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    color: var(--main-text-color);
}

li {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    color: var(--main-text-color);
}

button {
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    color: var(--main-text-color);
}

input {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    color: var(--main-text-color);
}

textarea {
    font-family: Roboto, sans-serif;
    font-weight: 400;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.container {
    position: relative;
    padding: 0 20px;
    max-width: 1280px;
    width: 1280px;
    margin: 0 auto;
}

/* CUSTOM SCROLLBAR */

.custom-scroll::-webkit-scrollbar {
    transition: 0.2s;
    background-color: transparent;
    width: 5px;
}

.custom-scroll::-webkit-scrollbar-track-piece {
    width: 6px;
}

.custom-scroll::-webkit-scrollbar-thumb {
    transition: 0.2s;
    height: 10%;
    background-color: var(--iface-white20);
    width: 6px;
    border-radius: 5px;
    cursor: grab;
    max-height: 100px;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
    background-color: var(--iface-white24);
}

.custom-scroll::-webkit-scrollbar-thumb:active {
    cursor: grabbing;
}

.custom-scroll::-webkit-scrollbar-track {
    margin: 5px 0 5px 0;
}

.slider .swiper-wrapper {
    transition-timing-function: linear;
}

.skeleton::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
        to top right,
        var(--iface-white6) 0%,
        var(--iface-white10) 15%,
        var(--iface-white6),
        var(--iface-white10) 45%,
        var(--iface-white6),
        var(--iface-white10) 75%,
        var(--iface-white6) 100%
    );
    background-repeat: no-repeat;
    background-size: 400%;
    animation: Shimmer 5s linear infinite;
    border-radius: 16px;
}

.skeleton-large::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
        to top right,
        var(--iface-white6) 0%,
        var(--iface-white10) 15%,
        var(--iface-white6),
        var(--iface-white10) 45%,
        var(--iface-white6),
        var(--iface-white10) 75%,
        var(--iface-white6) 100%
    );
    background-repeat: no-repeat;
    background-size: 2400px 400px;
    animation: Shimmer 5s linear infinite;
    border-radius: 16px;
}

@keyframes Shimmer {
    0% {
        background-position: -150px 0px;
    }
    25% {
        background-position: 0 0;
    }
    50% {
        background-position: -150px 0px;
    }
    75% {
        background-position: 0 0;
    }
    100% {
        background-position: -150px 0px;
    }
}

@keyframes Loader {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(-360deg);
    }
}

@keyframes LoaderReverse {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
}

@media screen and (max-width: 1260px) {
    .container {
        padding: 0 60px;
        max-width: 100%;
        width: 100%;
    }
}

@media screen and (max-width: 1024px) {
    .container {
        padding: 0 20px;
    }

    body {
        overflow-x: auto;
    }
}

@media screen and (max-width: 480px) {
    .container {
        padding: 0 10px;
    }
}
</style>
