<template>
    <div class="modal"
         @click.self="$emit('closeModal')"
    >
        <div class="modal__content">
            <div class="modal__group">
                <h2 class="modal__title">{{ title }}</h2>
                <button class="modal__close-btn"
                        @click="$emit('closeModal')"
                >
                    <CloseIcon />
                </button>
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
import CloseIcon from "@/assets/earn/all-pools/CloseIcon.vue";

export default {
    name: "ModalWrapper",
    components: {CloseIcon},
    props: {
        title: {
            type: String,
            default() {
                return ''
            }
        }
    },
    data() {
        return {}
    }
}
</script>

<style scoped>
.modal {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(10, 7, 6, 0.8);
}

.modal__content {
    width: 450px;
    z-index: 999;
    background: #191919;
    backdrop-filter: blur(10px);
    border-radius: 12px;
    padding: 18px;
}

.modal__group {
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal__title {
    font-size: 24px;
    line-height: normal;
    font-weight: 500;
}

.modal__close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 8px;
    outline: none;
    background: var(--iface-white10);
}

@media screen and (max-width: 768px) {
    .modal__content {
        z-index: 999;
        position: fixed;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 0;
    }
}
</style>
