<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.063 9.09917C16.063 9.44084 15.788 9.72417 15.4463 9.72417H15.438C15.0963 9.72417 14.813 9.44084 14.813 9.09917C14.813 8.7575 15.0963 8.47417 15.438 8.47417C15.788 8.47417 16.063 8.7575 16.063 9.09917ZM13.8796 9.14917C13.8796 9.96584 14.5463 10.6325 15.363 10.6325H18.7046C18.8427 10.6325 18.9546 10.5206 18.9546 10.3825V7.90748C18.9546 7.7694 18.8427 7.65747 18.7046 7.65747H15.363C14.5463 7.65747 13.8796 8.32415 13.8796 9.14917Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6296 9.14925C12.6296 7.64088 13.8546 6.40755 15.3629 6.40755H18.702C18.8411 6.40755 18.9542 6.29387 18.9451 6.1551C18.7914 3.78898 16.8279 1.90755 14.4212 1.90755H7.64622C5.15455 1.90755 3.12122 3.94088 3.12122 6.44088V10.2693C3.12122 10.4214 3.25707 10.5486 3.40919 10.5512C3.5283 10.5532 3.6483 10.5576 3.70749 10.5576C6.52416 10.5576 8.57123 12.8242 8.57123 15.6409C8.57123 15.8622 8.55573 16.0795 8.52748 16.293C8.50698 16.4484 8.62457 16.5909 8.78132 16.5909H14.4212C16.9401 16.5909 18.8262 14.5547 18.9482 12.1325C18.9552 11.9946 18.8426 11.8826 18.7046 11.8826H15.3629C13.8546 11.8826 12.6296 10.6576 12.6296 9.14925Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.20195 14.5778H4.55945V12.9362C4.55945 12.5912 4.27945 12.3112 3.93445 12.3112C3.58945 12.3112 3.30945 12.5912 3.30945 12.9362V14.5778H1.67029C1.32445 14.5778 1.04529 14.8578 1.04529 15.2028C1.04529 15.5478 1.32445 15.8278 1.67029 15.8278H3.30945V17.4695C3.30945 17.8145 3.58945 18.0945 3.93445 18.0945C4.27945 18.0945 4.55945 17.8145 4.55945 17.4695V15.8278H6.20195C6.54695 15.8278 6.82696 15.5478 6.82696 15.2028C6.82696 14.8578 6.54695 14.5778 6.20195 14.5778Z" fill="white"/>
    </svg>
</template>

<script>
export default {
    name: "WalletIcon",
    data() {
        return {}
    }
}
</script>

<style scoped>

</style>
