<template>
    <svg :class="customClass" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g opacity="0.6">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.71878 3.65385C6.48039 3.65385 3.85542 6.29437 3.85542 9.55193C3.85542 12.8096 6.48047 15.4508 9.71878 15.4508C11.2471 15.4508 12.6389 14.8625 13.6825 13.8986C13.6859 13.895 13.6895 13.8914 13.693 13.8878C13.7071 13.8736 13.7217 13.8604 13.7367 13.848C14.8728 12.7722 15.5821 11.2453 15.5821 9.55193C15.5821 6.29437 12.9571 3.65385 9.71878 3.65385ZM14.9089 14.2929C16.0399 13.0405 16.7291 11.3772 16.7291 9.55193C16.7291 5.65713 13.5906 2.5 9.71878 2.5C5.84689 2.5 2.70837 5.65713 2.70837 9.55193C2.70837 13.4467 5.8468 16.6046 9.71878 16.6046C11.363 16.6046 12.8751 16.0351 14.0706 15.0815L16.3131 17.3316C16.5374 17.5566 16.9005 17.5561 17.1243 17.3305C17.3479 17.1049 17.3475 16.7396 17.1232 16.5146L14.9089 14.2929Z" fill="white"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: "SearchIcon",
    props: {
        customClass: {
            type: String,
            default() {
                return ''
            }
        }
    },
    data() {
        return {}
    }
}
</script>

<style scoped>

</style>
