<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0342 19.6069H15.8903C15.498 19.6069 15.1797 19.919 15.1797 20.3035C15.1797 20.6879 15.498 21 15.8903 21H18.0342C18.4264 21 18.7447 20.6879 18.7447 20.3035C18.7447 19.919 18.4264 19.6069 18.0342 19.6069Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.11312 19.6069H5.96923C5.57702 19.6069 5.2587 19.919 5.2587 20.3035C5.2587 20.6879 5.57702 21 5.96923 21H8.11312C8.50533 21 8.82365 20.6879 8.82365 20.3035C8.82365 19.919 8.50533 19.6069 8.11312 19.6069Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.285 9.50247C13.9316 9.50247 13.5991 9.63807 13.349 9.88325C12.8336 10.3894 12.8336 11.2151 13.349 11.7222C13.6057 11.9701 13.9439 12.0946 14.2831 12.0946L14.285 12.0937H14.2869C14.626 12.0927 14.9652 11.9683 15.2238 11.7185C15.7373 11.2151 15.7373 10.3904 15.221 9.88325C14.9709 9.63807 14.6383 9.50247 14.285 9.50247Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9263 11.4895L17.2873 11.6957C17.6264 11.8898 17.7411 12.3161 17.5431 12.6486C17.4114 12.8696 17.1736 12.9941 16.9292 12.9941C16.8079 12.9941 16.6847 12.9634 16.572 12.9003L16.2281 12.7034L16.2262 12.7062C15.8738 13.0479 15.4456 13.2662 14.9956 13.3832V13.7937C14.9956 14.1782 14.6773 14.4903 14.2851 14.4903C13.8928 14.4903 13.5745 14.1782 13.5745 13.7937V13.3841C13.1255 13.2671 12.6982 13.0498 12.3467 12.709C12.3448 12.7071 12.3439 12.7043 12.342 12.7025L11.9972 12.9003C11.8844 12.9634 11.7613 12.9941 11.64 12.9941C11.3956 12.9941 11.1578 12.8706 11.0252 12.6486C10.8281 12.3161 10.9427 11.8898 11.2819 11.6957L11.6447 11.4886C11.5244 11.0372 11.5235 10.5664 11.6447 10.1159L11.2819 9.90975C10.9427 9.71658 10.8281 9.28936 11.0252 8.9578C11.2232 8.62439 11.658 8.51201 11.9962 8.70519L12.3411 8.90208L12.3439 8.89929C12.6868 8.56124 13.1179 8.3467 13.5745 8.23247V7.81175C13.5745 7.42726 13.8928 7.1152 14.2851 7.1152C14.6773 7.1152 14.9956 7.42726 14.9956 7.81175V8.23339C15.4522 8.34763 15.8823 8.56216 16.2262 8.89837L16.2291 8.90115L16.572 8.70519C16.9121 8.51108 17.3469 8.62532 17.544 8.9578C17.7411 9.29029 17.6255 9.71658 17.2863 9.90975L16.9263 10.115C17.0476 10.5664 17.0476 11.0382 16.9263 11.4895ZM8.12526 13.0869H8.49474C8.88695 13.0869 9.20526 13.399 9.20526 13.7835C9.20526 14.168 8.88695 14.48 8.49474 14.48H8.12526C7.15326 14.48 6.36221 13.7046 6.36221 12.7517V8.85193C6.36221 7.89998 7.15326 7.12449 8.12526 7.12449H8.49474C8.88695 7.12449 9.20526 7.43654 9.20526 7.82104C9.20526 8.20553 8.88695 8.51759 8.49474 8.51759H8.12526C7.93389 8.51759 7.78326 8.66433 7.78326 8.85193V12.7517C7.78326 12.9365 7.93674 13.0869 8.12526 13.0869ZM16.5644 3H7.43653C4.99042 3 3 4.95033 3 7.34739V14.2581C3 16.6542 4.99042 18.6036 7.43653 18.6036H16.5635C19.0105 18.6036 21 16.6542 21 14.2581V7.34739C21 4.95033 19.0105 3 16.5644 3Z" fill="white"/>
    </svg>
</template>

<script>
export default {
    name: "StakeIcon",
    data() {
        return {}
    }
}
</script>

<style scoped>

</style>
