<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21.5C11.2461 21.5 10.4971 21.4081 9.77465 21.2252C9.34824 21.1182 9.01416 20.7515 8.90089 20.2693C8.76764 19.698 8.41547 19.2242 7.90721 18.9315C7.44368 18.6652 6.9021 18.5857 6.37861 18.7079C5.89699 18.8235 5.41824 18.687 5.13079 18.3545C4.39124 17.4942 3.82967 16.5231 3.46037 15.4667C3.29666 14.9986 3.47179 14.4652 3.90772 14.109C4.39314 13.712 4.67107 13.1255 4.67107 12.4993C4.67107 11.8739 4.39314 11.2875 3.90772 10.8905C3.47179 10.5342 3.29666 10.0008 3.46037 9.53275C3.82967 8.4754 4.39219 7.50427 5.13079 6.64588C5.41728 6.31238 5.89699 6.17689 6.38051 6.29153C6.90115 6.41375 7.44368 6.33322 7.90721 6.06699C8.41547 5.77518 8.76764 5.30145 8.89994 4.73204C9.01416 4.24694 9.34824 3.88123 9.7756 3.77322C11.2185 3.4094 12.7785 3.40845 14.2253 3.77322C14.6526 3.88123 14.9867 4.24789 15.099 4.73204C15.2313 5.3005 15.5845 5.77518 16.0927 6.06699C16.5572 6.33512 17.0988 6.41375 17.6204 6.29153C18.102 6.17595 18.5817 6.31238 18.8682 6.64493C19.6058 7.50237 20.1674 8.4735 20.5386 9.53275C20.7033 10.0008 20.5272 10.5342 20.0913 10.8905C19.6068 11.2884 19.3289 11.8749 19.3289 12.4993C19.3289 13.1246 19.6068 13.711 20.0922 14.1099C20.5272 14.4652 20.7033 14.9986 20.5396 15.4667C20.1674 16.5259 19.6058 17.497 18.8691 18.3535C18.5817 18.6861 18.1048 18.8206 17.6185 18.7079C17.0969 18.5866 16.5563 18.6652 16.0927 18.9315C15.5845 19.2242 15.2313 19.6989 15.099 20.2674C14.9867 20.7506 14.6526 21.1182 14.2243 21.2252C13.5029 21.4081 12.7528 21.5 12 21.5ZM9.43105 12.5006C9.43105 11.0879 10.5818 9.94243 12.0009 9.94243C13.4201 9.94243 14.5708 11.0879 14.5708 12.5006C14.5708 13.9132 13.4201 15.0587 12.0009 15.0587C10.5818 15.0587 9.43105 13.9132 9.43105 12.5006Z" fill="white"/>
    </svg>
</template>

<script>
export default {
    name: "SettingsIcon",
    data() {
        return {}
    }
}
</script>

<style scoped>

</style>
