<template>
    <div class="sidebar">
        <div class="sidebar__logo-group">
            <div class="sidebar__logo-wrapper">
                <SwapLogo/>
            </div>
            <SwapLogoText />
        </div>
        <nav class="sidebar__nav nav">
            <div class="nav__group">
                <div class="nav__item item disabled">
                    <div class="item__group">
                        <div class="item__icon-wrapper">
                            <PortfolioIcon />
                        </div>
                        <p class="sidebar__text disabled_text">{{ $t("dexSidebar.portfolio") }}</p>
                    </div>
                    <InterfaceTag tag="soon" />
                </div>
                <router-link :to="{name: 'Claim'}" :active-class="'active_item'" class="nav__item item">
                    <div class="item__group">
                        <div class="item__icon-wrapper">
                            <ClaimIcon />
                        </div>
                        <p class="sidebar__text">{{ $t("dexSidebar.claim") }}</p>
                    </div>
                </router-link>
            </div>
            <div class="dividing-line"></div>
            <div class="nav__group">
                <div class="nav__category item"
                     :class="{active_item: isActiveRoute('Dex') || isActiveRoute('Limit')}"
                     @click="showAdditionalMenu = !showAdditionalMenu"
                >
                    <div class="item__group">
                        <div class="item__icon-wrapper">
                            <DexIcon />
                        </div>
                        <p class="sidebar__text">{{ $t("dexSidebar.dex") }}</p>
                    </div>
                    <ArrowIcon :custom-class="showAdditionalMenu ? 'rotated-icon' : 'arrow-icon'"/>
                </div>
                <transition name="slide">
                    <div v-show="showAdditionalMenu" class="additional-items">
                        <router-link :to="{name: 'Dex'}" :active-class="'active_item'" class="nested-item">
                            <div class="status-dot"></div>
                            <div class="nav__item item">
                                <p class="sidebar__text">{{ $t("dexNavigation.swap") }}</p>
                            </div>
                        </router-link>
                        <div class="nested-item">
                            <div class="nav__item item disabled">
                                <p class="sidebar__text disabled_text">{{ $t("dexNavigation.limit") }}</p>
                                <InterfaceTag :tag="'soon'" />
                            </div>
                        </div>
                    </div>
                </transition>
                <div class="nav__category item"
                     :class="{active_item: isActiveRoute('AllPools') || isActiveRoute('PoolPage')}"
                     @click="showEarnMenu = !showEarnMenu"
                >
                    <div class="item__group">
                        <div class="item__icon-wrapper">
                            <EarnIcon />
                        </div>
                        <p class="sidebar__text">{{ $t("dexSidebar.earn") }}</p>
                    </div>
                    <ArrowIcon :custom-class="showEarnMenu ? 'rotated-icon' : 'arrow-icon'"/>
                </div>
                <transition name="slide">
                    <div v-show="showEarnMenu" class="additional-items">
                        <div class="nested-item"
                             :class="{active_item: isActiveRoute('AllPools') || isActiveRoute('PoolPage')}"
                             @click="navigateTo('AllPools')"
                        >
                            <div class="status-dot"></div>
                            <div class="nav__item item">
                                <p class="sidebar__text">{{ $t("dexSidebar.pools") }}</p>
                            </div>
                        </div>
                        <div class="nested-item">
                            <div class="nav__item item disabled">
                                <p class="sidebar__text disabled_text">{{ $t("dexSidebar.stake") }}</p>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
            <div class="dividing-line"></div>
            <div class="nav__group">
                <router-link :to="{name: 'Stake'}" :active-class="'active_item'" class="nav__item item">
                    <div class="item__group">
                        <div class="item__icon-wrapper">
                            <StakeIcon />
                        </div>
                        <p class="sidebar__text">{{ $t("dexSidebar.stake") }}</p>
                    </div>
                    <InterfaceTag tag="new" />
                </router-link>
                <div class="nav__category item"
                     :class="{active_item:
                        isActiveRoute('Contest') || isActiveRoute('ContestPage') ||
                        isActiveRoute('Cashback') || isActiveRoute('CashbackPage') ||
                        isActiveRoute('Referral')
                     }"
                     @click="showBenefits = !showBenefits"
                >
                    <div class="item__group">
                        <div class="item__icon-wrapper">
                            <BenefitsIcon />
                        </div>
                        <p class="sidebar__text">{{ $t("dexSidebar.benefits") }}</p>
                    </div>
                    <ArrowIcon :custom-class="showBenefits ? 'rotated-icon' : 'arrow-icon'"/>
                </div>
                <transition name="slide">
                    <div class="additional-items"
                         v-show="showBenefits"
                    >
                        <router-link :to="{name: 'Referral'}" :active-class="'active_item'" class="nested-item">
                            <div class="status-dot"></div>
                            <div class="nav__item item">
                                <p class="sidebar__text">{{ $t("dexNavigation.referral") }}</p>
                            </div>
                        </router-link>
                        <div class="nested-item"
                             :class="{ 'active_item': isActiveRoute('Cashback') || isActiveRoute('CashbackItemPage') }"
                             @click="navigateTo('Cashback')"
                        >
                            <div class="status-dot"></div>
                            <div class="nav__item item">
                                <p class="sidebar__text">{{ $t("dexNavigation.cashback") }}</p>
                            </div>
                        </div>
                        <div class="nested-item"
                             :class="{ 'active_item': isActiveRoute('Contests') || isActiveRoute('ContestPage') }"
                             @click="navigateTo('Contests')"
                        >
                            <div class="status-dot"></div>
                            <div class="nav__item item relative_wrapper">
                                <p class="sidebar__text"
                                   ref="contest"
                                   :title="showTooltip ? $t('dexNavigation.contests') : ''"
                                >
                                    {{ $t("dexNavigation.contests") }}
                                </p>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
            <div class="dividing-line"></div>
            <div class="nav__group">
                <a href="" target="_blank" class="nav__item item">
                    <div class="item__group">
                        <div class="item__icon-wrapper">
                            <DocumentationIcon />
                        </div>
                        <p class="sidebar__text">{{ $t("dexSidebar.documentation") }}</p>
                    </div>
                </a>
                <a href="" target="_blank" class="nav__item item">
                    <div class="item__group">
                        <div class="item__icon-wrapper">
                            <WhitePaperIcon />
                        </div>
                        <p class="sidebar__text">{{ $t("dexSidebar.whitePaper") }}</p>
                    </div>
                </a>
                <button @click="showSettings" class="nav__item item">
                    <div class="item__group">
                        <div class="item__icon-wrapper">
                            <SettingsIcon />
                        </div>
                        <p class="sidebar__text">{{ $t('dexSidebar.settings') }}</p>
                    </div>
                </button>
            </div>
        </nav>
    </div>
    <DexGlobalSettings
        v-show="showSettingsMenu"
        @closeSettings="closeSettings"
    />
</template>

<script>
import SwapLogo from "@/assets/earn/sidebar/SwapLogo.vue";
import SwapLogoText from "@/assets/earn/sidebar/SwapLogoText.vue";
import InterfaceTag from "@/components/ui/InterfaceTag.vue";
import ArrowIcon from "@/assets/earn/sidebar/ArrowIcon.vue";
import PortfolioIcon from "@/assets/earn/sidebar/PortfolioIcon.vue";
import DexIcon from "@/assets/earn/sidebar/DexIcon.vue";
import ClaimIcon from "@/assets/earn/sidebar/ClaimIcon.vue";
import EarnIcon from "@/assets/earn/sidebar/EarnIcon.vue";
import StakeIcon from "@/assets/earn/sidebar/StakeIcon.vue";
import DocumentationIcon from "@/assets/earn/sidebar/DocumentationIcon.vue";
import WhitePaperIcon from "@/assets/earn/sidebar/WhitePaperIcon.vue";
import SettingsIcon from "@/assets/earn/sidebar/SettingsIcon.vue";
import BenefitsIcon from "@/assets/earn/sidebar/BenefitsIcon.vue";
import DexGlobalSettings from "@/components/dex/DexGlobalSettings.vue";

export default {
    name: "EarnSidebar",
    components: {
        DexGlobalSettings,
        BenefitsIcon,
        SettingsIcon,
        WhitePaperIcon,
        DocumentationIcon,
        StakeIcon,
        EarnIcon,
        ClaimIcon,
        DexIcon,
        PortfolioIcon,
        ArrowIcon,
        InterfaceTag,
        SwapLogoText,
        SwapLogo
    },
    data() {
        return {
            showAdditionalMenu: true,
            showEarnMenu: true,
            showBenefits: true,
            showSettingsMenu: false,
            showTooltip: false,
            debounce: null
        }
    },
    methods: {
        showSettings() {
            this.showSettingsMenu = true
        },
        closeSettings() {
            this.showSettingsMenu = false
        },
        isActiveRoute(routeName) {
            return this.$route.name === routeName;
        },
        navigateTo(routeName) {
            this.$router.push({name: routeName});
        },
        checkIsTrim(e) {
            clearTimeout(this.debounce)
            this.debounce = setTimeout(() => {
                const element = this.$refs.contest
                if (element.scrollWidth > 0 && element.clientWidth > 0) {
                    this.showTooltip = element.scrollWidth > element.clientWidth;
                }
            }, 100)
        },
        hideTooltip() {
            this.showTooltip = false
        }
    },
    mounted() {
        this.checkIsTrim()
    },
    watch: {
        GET_USER_SETTINGS: {
            handler() {
                this.checkIsTrim()
            },
            deep: true
        }
    }
}
</script>

<style scoped>
    .sidebar {
        width: 250px;
        min-width: 250px;
        overflow-y: auto;
        max-height: calc(100vh - 20px);
    }

    .sidebar::-webkit-scrollbar {
        width: 0;
        opacity: 0;
    }

    .sidebar__logo-group {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 8px;
        margin-bottom: 8px;
    }

    .sidebar__logo-wrapper {
        width: 32px;
        height: 32px;
        padding: 4px;
        border-radius: 12px;
        background: #37262C;
    }

    .nav {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .nav__group {
        display: flex;
        flex-direction: column;
        gap: 2px;
    }

    .dividing-line {
        width: calc(100% - 30px);
        height: 0.5px;
        background: var(--iface-white10);
        margin: 0 15px;
    }

    .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px;
        background: transparent;
        width: 100%;
        border: none;
        outline: none;
        transition: .2s ease-out;
    }

    .item:hover {
        background: var(--iface-white4);
        border-radius: 12px;
    }

    .disabled {
        cursor: not-allowed;
    }

    .disabled .item__icon-wrapper {
        opacity: 0.6;
    }

    .disabled_text {
        opacity: 0.6;
    }

    .disabled:hover {
        background: transparent;
    }

    .nested-item {
        margin-top: 2px;
        position: relative;
        display: block;
        margin-left: 31px;
    }

    .nested-item .item {
        padding: 10px;
    }

    .active_item {
        background: var(--iface-white4);
        border-radius: 12px;
    }

    .item__group {
        display: flex;
        align-items: center;
    }

    .item__icon-wrapper {
        margin-right: 8px;
        width: 24px;
        height: 24px;
    }

    .sidebar__text {
        font-size: 14px;
        line-height: 17px;
    }

    .status-dot {
        position: absolute;
        left: -17px;
        top: 16px;
        transition: .2s;
        min-width: 4px;
        width: 4px;
        height: 4px;
        background: var(--main-text-color);
        border-radius: 100px;
        opacity: 0;
    }

    .nested-item:hover .status-dot {
        opacity: 1;
        background: var(--main-green);
    }

    .arrow-icon {
        transition: .2s ease-out;
        transform: rotateX(180deg);
    }

    .rotated-icon {
        transition: .2s ease-out;
        transform: rotateX(0deg);
    }

    .active_item .arrow-icon {
        transform: rotateX(0deg);
    }
</style>
